var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _vm.modal.goal
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.modal.show,
                callback: function($$v) {
                  _vm.$set(_vm.modal, "show", $$v)
                },
                expression: "modal.show"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.modal.goal.title))
                  ]),
                  _c("v-card-subtitle", { staticClass: "mt-1" }, [
                    _vm._v(_vm._s(_vm.modal.goal.description))
                  ]),
                  _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "row mx-1" },
                      [
                        _c("p", { staticClass: "col-6 text-start pa-0 my-0" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "models.goal.attributes.states." +
                                  _vm.modal.goal.state
                              )
                            )
                          )
                        ]),
                        _c("p", { staticClass: "col-6 text-end pa-0 my-0" }, [
                          _vm._v(_vm._s(_vm.modal.goal.progress) + "%")
                        ]),
                        _c("v-progress-linear", {
                          attrs: {
                            rounded: "",
                            height: "7",
                            value: _vm.modal.goal.progress
                          }
                        }),
                        _vm.modal.goal.supportingDocument
                          ? _c(
                              "div",
                              { staticClass: "mt-5 mb-2" },
                              [
                                _vm.modal.goal.assignee.id ===
                                this.currentUser.id
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "px-0",
                                        attrs: {
                                          text: "",
                                          small: "",
                                          color: "text"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.downloadGoalAttachment({
                                              filename:
                                                _vm.modal.goal
                                                  .supportingDocumentName,
                                              url: _vm.getFileUrl(
                                                _vm.modal.goal
                                                  .supportingDocument
                                              )
                                            })
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "pl-0 pr-1" },
                                          [_vm._v("mdi-download-outline")]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.modal.goal
                                                .supportingDocumentName
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : [
                                      _c(
                                        "v-icon",
                                        { staticClass: "pl-0 pr-1" },
                                        [_vm._v("mdi-attachment")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.modal.goal
                                              .supportingDocumentName
                                          )
                                        )
                                      ])
                                    ]
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }